iframe {
  display: none;
}
body,html{
  background: #151C34;
}
.container{
  margin: auto;
  padding: 0 .7rem;
  width: 100%;
}

.Toastify__toast{
  font-size: .14rem;
  word-wrap: break-word;
  width: 100%;
}