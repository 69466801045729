.mintCom{
  width: 100%;
  min-height: 100vh;
  background: url(../../assets/img/bg_circle.png) no-repeat top center;
  background-size: 4.8rem 4.8rem;
  background-position-y: 5%;
  overflow-x: hidden;
  .header{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    padding-top: 1.42rem;
    .logo{
      width: .7rem;
      margin-right: .2rem;
    }
    .text{
      .name{
        font-size: .38rem;
        line-height: 1;
        font-weight: 600;
      }
      .prizepool{
        font-size: .88rem;
        line-height: 1;
        font-weight: 800;
      }
    }
  }
  .mintBox{
    color: #fff;
    width: 80%;
    margin: auto;
    max-width: 4.5rem;
    background-color: #1E263E;
    border-radius: .08rem;
    margin-top: 1.6rem;
    padding: .17rem .2rem .34rem;
    .address{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: .18rem;
      line-height: 1;
      font-family: "AVBlock";
      .icon{
        display: block;
        width: .2rem;
        margin-left: .13rem;
      }
    }
    .from{
      margin-top: .35rem;
      .from-item{
        .lable{
          font-size: .12rem;
          line-height: 1.4;
          font-family: "AVBlock";
          margin-bottom: .1rem;
        }
        .dropdown {
          position: relative;
          color: #fff;
          font-family: "AVBlock";
          button{
            background-color: #151C34;
            padding: .07rem .15rem;
            border-radius: .09rem;
            outline: none;
            width: 100%;
            border: none;
            color: #fff;
            display: flex;
            align-items: center;
            img{
              width: .28rem;
              margin-right: .1rem;
            }
            font-size: .18rem;
            line-height: 1.4;
            font-family: "AVBlock";
          }
          .menu {
            position: absolute;
            list-style-type: none;
            padding: 0;
            width: 100%;
            color: #fff;
            background-color: #232A44;
            padding: .07rem .05rem;
            border-radius: .09rem;
            height: 2rem;
            overflow-y: scroll;
            li {
              display: flex;
              align-items: center;
              font-size: .18rem;
              line-height: 1.4;
              padding: .05rem .1rem;
              border-radius: .08rem;
              cursor: pointer;
              img{
                width: .28rem;
                margin-right: .1rem;
              }
              &:hover {
                background-color: #151C34;
              }
            }
          }
         
        }
        
        .count{
          margin-top: .28rem;
          border-radius: .08rem;
          overflow: hidden;
          background-color: #151C34;
          .inputGroup{
            width: 100%;
            display: flex;
            align-items: stretch;
            >*{
              width: 33.33%;
              height: .38rem;
              border: none;
              text-align: center;
              outline: none;
              font-size: .18rem;
              line-height: 1.4;
              font-family: "AVBlock";
              background-color: #151C34;
              color: #fff;
            }
            input{
              border: .01rem solid #fff;
              border-radius: .08rem;
            }
          }
        }
        .tip{
          font-size: .18rem;
          line-height: 1.4;
          font-family: "AVBlock";
          color: #fff;
          margin-top: .16rem;
          margin-bottom: .07rem;
        }
      }
      .from-btn{
        display: flex;
        justify-content: center;
        .mintBtn{
          width: 1.2rem;
          color: #fff;
          font-size: .28rem;
          line-height: 1.4;
          font-family: "AVBlock";
          background-color: #151C34;
          border: none;
          outline: none;
          margin: auto;
          padding: .05rem 0;
          border-radius: .08rem;
          display: flex;
          align-items: center;
          justify-content: center;
          height: .5rem;
          border: .01rem solid #fff;
          &:disabled{
            color: #262E4A;
          }
        }
      }
    }
  }
}


@media screen and (max-width: 750px) {
  .container{
    padding: 0 0.2rem;
  }
  .mintCom {
    width: 100%;
    background-position-y: 1%;
    .header{
      padding-top: 1.42rem;
      .logo{
        width: .7rem;
        margin-right: .2rem;
      }
      .text{
        .name{
          font-size: .18rem;
          line-height: 1;
          font-weight: 600;
          margin-bottom: .2rem;
        }
        .prizepool{
          font-size: .38rem;
          line-height: 1;
          font-weight: 800;
        }
      }
    }
    .mintBox{
      width: 100%;
      margin-top: 1.2rem;
    }
  }
  
}


.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

.spinner-border {
  display: inline-block;
  width: .3rem;
  height: .3rem;
  border: 0.28em solid #ffffff;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: .75s linear infinite spinner-border;
  animation: .75s linear infinite spinner-border;
}

@keyframes spinner-border {
  100% {
    transform: rotate(360deg);
  }
}

