.home {
  width: 100%;
  min-height: 100vh;
  background: url(../../assets/img/bg_circle.png) no-repeat top center;
  background-size: 4.8rem 4.8rem;
  background-position-y: 5%;
  overflow-x: hidden;
  .header{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    padding-top: 1.42rem;
    .logo{
      width: .7rem;
      margin-right: .2rem;
    }
    .text{
      .name{
        font-size: .38rem;
        line-height: 1;
        font-weight: 600;
      }
      .prizepool{
        font-size: .88rem;
        line-height: 1;
        font-weight: 800;
      }
    }
  }
  .main{
    display: flex;
    align-items: flex-start;
    margin: 0 .2rem;
    margin-top: .85rem;
    >.left{
      // width: 70%;
      padding: 0 .2rem;
      flex: 1;
      .selectTime{
        background-color: #1E263E;
        color: #fff;
        display: flex;
        align-items: center;
        font-size: .12rem;
        line-height: 1.2;
        font-family: "AVBlock";
        padding: .2rem .2rem;
        border-radius: .08rem;
        .tit{
          padding: .15rem .25rem;
        }
        .item{
          padding: .10rem .25rem;
          transition: all linear .3s;
          border-radius: .04rem;
          &:hover,&.active{
            background: #2D334A;
            border: .01rem solid #DCDCDC;
          }
        }
      }
      .LEADERBOARD{
        // margin-top: .22rem;
        background-color: #1E263E;
        padding: .27rem .2rem;
        border-radius: .08rem;
        .title{
          font-family: "AVBlock";
          font-size: .12rem;
          line-height: 1.2;
          color: #fff;
          margin-bottom: .13rem;
        }
        .LEADERBOARDList{
          display: flex;
          align-items: flex-start;
          margin: 0 -0.2rem;
          .BoxList{
            width: 50%;
            padding: 0 .2rem;
            .item{
              padding: .06rem 0;
              display: flex;
              align-items: center;
              color: #fff;
              font-family: "AVBlock";
              line-height: 1;
              .ord{
                // width: .3rem;
                font-size: .18rem;
                text-align: left;
                min-width: .6rem;
                &.title{
                  font-size: .12rem;
                  margin-bottom: 0;
                }
              }
              .spec{
                flex: 1;
              }
              .name{
                flex: 1;
                display: flex;
                align-items: center;
                padding: 0 .1rem;
                img{
                  width: .22rem;
                  margin-right: .08rem;
                }
                font-size: .18rem;
              }
              .count{
                font-size: .18rem;
                min-width: .6rem;
                text-align: right;
                &.title{
                  font-size: .12rem;
                  margin-bottom: 0;
                  padding: 0;
                }
              }
            }
          }
        }
      }
    }
    >.right{
      // flex: 1;
      width: 4.45rem;
      .StartTime{
        background-color: #1E263E;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding: .19rem .4rem .24rem .13rem;
        font-family: "AVBlock";
        color: #fff;
        line-height: 1;
        border-radius: .08rem;
        .timeBox{
          .tit{
            font-size: .12rem;
            margin-bottom: .16rem;
          }
          .time{
            font-size: .38rem;
          }
        }
        .mint{
          .btn{
            font-size: .28rem;
            line-height: 1.4;
            background-color: #151C34;
            border: .01rem solid #F8F6F6;
            color: #fff;
            padding: .05rem .25rem;
            border-radius: .08rem;
          }
        }
      }
      .share{
        margin-top: .24rem;
        background-color: #1E263E;
        padding: .17rem .21rem;
        border-radius: .08rem;
        color: #fff;
        .address{
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: .18rem;
          line-height: 1;
          font-family: "AVBlock";
          .icon{
            display: block;
            width: .2rem;
            margin-left: .13rem;
          }
        }
        .link{
          margin-top: .28rem;
          .tit{
            font-size: .12rem;
            line-height: 1.3;
            margin-bottom: .07rem;
            color: #C7C7C7;
          }
          .copyLinkBox{
            background-color: #151C34;
            border-radius: .09rem;
            padding: .07rem .16rem;
            padding-right: .42rem;
            position: relative;
            font-size: .18rem;
            line-height: 1.4;
            display: flex;
            align-items: center;
            .link{
              margin-top: 0;
              width: 100%;
              max-width: 4rem;
              overflow: hidden;
              -moz-user-select:none; /* Firefox私有属性 */
              -webkit-user-select:none; /* WebKit内核私有属性 */
              -ms-user-select:none; /* IE私有属性(IE10及以后) */
              -khtml-user-select:none; /* KHTML内核私有属性 */
              -o-user-select:none; /* Opera私有属性 */
              user-select:none; /* CSS3属性 */
            }
            .copy{
              width: .2rem;
              position: absolute;
              right: .14rem;
              top: .09rem;
            }
          }
        }
        .reward{
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          margin-top: .28rem;
          .left{
            padding: 0;
            width: auto;
            .tit{
              font-size: .12rem;
              line-height: 1.3;
              margin-bottom: .14rem;
              color: #C7C7C7;
            }
            .balance{
              font-family: "AVBlock";
              font-size: .48rem;
              line-height: 1.2;
            }
          }
          .right{
            .btn{
              font-size: .12rem;
              line-height: 1.4;
              background-color: #151C34;
              border: .01rem solid #F8F6F6;
              color: #fff;
              padding: .05rem .1rem;
              border-radius: .08rem;
              margin-bottom: .1rem;
            }
          }
        }
        .tip{
          color: #fff;
          font-family: "AVBlock";
          font-size: .24rem;
          line-height: 1.2;
          margin-top: .4rem;
          text-align: center;
          padding: 0 .2rem;
          margin-bottom: .4rem;
        }
      }
    }
  }
}

.qa{
  padding: .7rem 0;
  color: #fff;
  .title{
    font-family: "AVBlock";
    font-size: .38rem;
    line-height: 1.4;
    margin-bottom: .34rem;
    text-align: center;
  }
  .collapse{
    width: 100%;
    max-width: 8.2rem;
    margin: auto;
    &-item{
      border-bottom: .01rem solid #d9d9d94e;
      font-family: "AVBlock";
      &-header{
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        padding: .2rem 0;
        color: #ffffff;
        line-height: 1;
        cursor: pointer;
        transition: all .3s,visibility 0s;
        font-size: .18rem;
        &-icon{
          width: .20rem;
          height: .20rem;
          margin-right: .08rem;
          path{
            fill: #42F2EF;
          }
          .open{
            display: none;
          }
        }
      }
      &-content{
        display: none;
        &-box{
          padding: .16rem;
          font-size: .14rem;
        }
      }
      &.active{
        .collapse-item-header{
          .collapse-item-header-icon{
            .close{
              display: none;
            }
            .open{
              display: block;
            }
          }
        }
        .collapse-item-content{
          display: block;
        }
      }
    }
  }
}

.modelConnectMask{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99999;
  .model-content{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .connectBox{
      width: 80%;
      max-width: 6rem;
      background-color: #1E263E;
      padding: .4rem .5rem .6rem;
      font-family: "AVBlock";
      .title{
        font-size: .38rem;
        line-height: 1.4;
        color: #fff;
      }
      .connectBtn{
        display: flex;
        align-items: center;
        border: .01rem solid #fff;
        background: #151C34;
        border-radius: .09rem;
        max-width: 3.85rem;
        margin: auto;
        padding: .2rem .25rem;
        margin-top: .6rem;
        cursor: pointer;
        img{
          width: .48rem;
          margin-right: .3rem;
        }
        color: #fff;
      }
    }
  }
}

.CountDown{
  width: 100%;
  .banner{
    padding-top: 56.6%;
    background: url(../../assets/img/bannerbg.png) no-repeat center;
    background-size: cover;
    position: relative;
    .countboxBox{
      max-width: 8rem;
      width: 100%;
      position: absolute;
      top: 3.1rem;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #fff;
      font-family: "AVBlock";
      .title{
        font-size: .56rem;
        line-height: 1.2;
      }
      .toMint{
        width: 3.8rem;
        color: #fff;
        font-size: .5rem;
        line-height: 1.4;
        font-family: "AVBlock";
        background-color: #151C34;
        border: none;
        outline: none;
        margin: auto;
        padding: .05rem 0;
        border-radius: .08rem;
        display: flex;
        align-items: center;
        justify-content: center;
        // height: .5rem;
        border: .01rem solid #fff;
        margin-top: .6rem;
      }
      .mint-count {
          &-down {
            display: flex;
            align-items: center;
            color: #FFF9D5;
            margin-top: .34rem;
            
            &-item {
              // width: 1.76rem;
              display: flex;
              flex-direction: column;
              align-items: center;
      
              // &:nth-child(1) {
              //   margin-right: .32rem;
              // }
      
              &-wrap {
                width: 100%;
                display: flex;
                justify-content: space-between;
      
                &-container {
                  min-width: .84rem;
                  height: .87rem;
                  box-sizing: border-box;
                  // background-color: rgba(255, 255, 255, 0.2);
                  border-right: .01rem solid rgba(255, 255, 255, 0.8);
                  border-bottom: .01rem solid rgba(255, 255, 255, 0.8);
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: .54rem;
                  border-radius: .157rem;
                  overflow: hidden;
                  position: relative;
                  padding: .1rem;

      
                  &-blur {
                    box-sizing: border-box;
                    position: absolute;
                    left: -0.1rem;
                    right: -0.1rem;
                    top: -0.1rem;
                    bottom: -0.1rem;
                    background-color: rgba(255, 255, 255, 0.3);
                    filter: blur(3.49px);
                    // border: .01rem solid rgba(255, 255, 255, 1);
                  }
      
                  &-text {
                    position: relative;
                    z-index: 4;
                  }
      
                 
                }
              }
      
              &-title {
                margin-top: .15rem;
                font-size: .23rem;
                line-height: .34rem;
              }
            }
      
            &-doll {
              font-size: .48rem;
              margin: 0 .1rem;
              // margin-top: -0.55rem;
              line-height: 1;
            }
          }
        }
    }
  }
}

.app{
  position: relative;
  .nav{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: .36rem;
    right: 1.28rem;
    z-index: 99;
    .item{
      display: block;
      width: .64rem;
      // height: .48rem;
      margin: 0 .12rem;
    }
  }
}

@media screen and (max-width: 750px) {
  .container{
    padding: 0 0.2rem;
  }
  .home {
    width: 100%;
    background-position-y: 1%;
    .header{
      padding-top: 1.42rem;
      .logo{
        width: .7rem;
        margin-right: .2rem;
      }
      .text{
        .name{
          font-size: .52rem;
          line-height: 1;
          font-weight: 600;
          margin-bottom: .2rem;
        }
        .prizepool{
          font-size: .52rem;
          line-height: 1;
          font-weight: 800;
        }
      }
    }
    .main{
      flex-direction: column;
      margin: 0 .2rem;
      margin-top: .85rem;
      >.left{
        width: 100%;
        padding: 0;
        margin-top: .24rem;
        .selectTime{
          background-color: #1E263E;
          color: #fff;
          display: flex;
          align-items: center;
          font-size: .12rem;
          line-height: 1.2;
          font-family: "AVBlock";
          padding: .2rem .2rem;
          border-radius: .08rem;
          .tit{
            padding: .15rem .25rem;
          }
          .item{
            padding: .10rem .25rem;
            transition: all linear .3s;
            border-radius: .04rem;
            &:hover,&.active{
              background: #2D334A;
              border: .01rem solid #DCDCDC;
            }
          }
        }
        .LEADERBOARD{
          padding: .27rem 0;
          .title{
            padding: 0 .25rem;
          }
          .LEADERBOARDList{
            height: 5.4rem;
            flex-direction: column;
            overflow-x: hidden;
            overflow-y: scroll;
            padding: 0 .45rem;
            .BoxList{
              width: 100%;
              padding: 0rem;
              .item{
                padding: .06rem 0;
                .ord.title{
                  padding: 0;
                }
                .ord{
                  text-align: left;
                }
                &.hidePhone{
                  display: none;
                }
                &.header{
                  position: sticky;
                  top: 0;
                  background: #1E263E;
                }
              }
            }
          }
        }
      }
      >.right{
        order: -1;
        width: 100%;
        .StartTime{
          
          .timeBox{
            .tit{
              font-size: .12rem;
              margin-bottom: .16rem;
            }
            .time{
              font-size: .38rem;
            }
          }
          .mint{
            .btn{
              font-size: .28rem;
              line-height: 1.4;
              background-color: #151C34;
              border: .01rem solid #F8F6F6;
              color: #fff;
              padding: .05rem .25rem;
              border-radius: .08rem;
            }
          }
        }
        .share{
          margin-top: .24rem;
          background-color: #1E263E;
          padding: .17rem .21rem;
          border-radius: .08rem;
          color: #fff;
          .address{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: .18rem;
            line-height: 1;
            .icon{
              display: block;
              width: .2rem;
              margin-left: .13rem;
            }
          }
          .link{
            margin-top: .08rem;
            .tit{
              font-size: .12rem;
              line-height: 1.3;
              margin-bottom: .07rem;
              color: #C7C7C7;
            }
            .copyLinkBox{
              background-color: #151C34;
              border-radius: .09rem;
              padding: .07rem .16rem;
              padding-right: .42rem;
              position: relative;
              font-size: .18rem;
              line-height: 1.4;
              display: flex;
              align-items: center;
              .link{
                margin-top: 0;
              }
              .copy{
                width: .2rem;
                position: absolute;
                right: .14rem;
                top: .09rem;
              }
            }
          }
          .reward{
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            margin-top: .16rem;
            .left{
              padding: 0;
              width: auto;
              .tit{
                font-size: .12rem;
                line-height: 1.3;
                margin-bottom: .14rem;
                color: #C7C7C7;
              }
              .balance{
                font-family: "AVBlock";
                font-size: .48rem;
                line-height: 1.2;
              }
            }
            .right{
              .btn{
                font-size: .12rem;
                line-height: 1.4;
                background-color: #151C34;
                border: .01rem solid #F8F6F6;
                color: #fff;
                padding: .05rem .1rem;
                border-radius: .08rem;
                margin-bottom: .1rem;
              }
            }
          }
        }
      }
    }
   
  }

  .qa{
    padding: .7rem 0;
    color: #fff;
    .title{
      font-family: "AVBlock";
      font-size: .38rem;
      line-height: 1.4;
      margin-bottom: .34rem;
      text-align: center;
    }
    .collapse{
      padding: .2rem;
    }
  }
  .modelConnectMask{
    .model-content{
      .connectBox{
        .title{
          font-size: .28rem;
        }
        .connectBtn{
          font-size: .18rem;
          line-height: 1;
          img{
            width: .4rem;
          }
        }
      }
    }
  }

  .CountDown{
    .banner{
    
      .countboxBox{
        // top: 1.2rem;
        
        .title{
          font-size: .28rem;
        }
        
        .mint-count {
            &-down {
              display: flex;
              align-items: center;
              color: #FFF9D5;
              margin-top: .2rem;
              
              &-item {
                &-wrap {
        
                  &-container {
                    min-width: .64rem;
                    height: .57rem;
                    font-size: .32rem;
                    border-radius: .157rem;
                    padding: .06rem;
  
        
                    &-blur {
                      box-sizing: border-box;
                      position: absolute;
                      left: -0.1rem;
                      right: -0.1rem;
                      top: -0.1rem;
                      bottom: -0.1rem;
                      background-color: rgba(255, 255, 255, 0.3);
                      filter: blur(3.49px);
                      // border: .01rem solid rgba(255, 255, 255, 1);
                    }
        
                    &-text {
                      position: relative;
                      z-index: 4;
                    }
        
                   
                  }
                }
        
                &-title {
                  margin-top: .15rem;
                  font-size: .23rem;
                  line-height: .34rem;
                }
              }
        
              &-doll {
                font-size: .48rem;
                margin: 0 .1rem;
                // margin-top: -0.55rem;
                line-height: 1;
              }
            }
          }
      }
    }
  }
  
  
}

@media screen and (max-width: 420px) {
  .CountDown .banner .countboxBox {
    top: 1.2rem;
  }
  .app{
    .nav{
      top: .16rem;
      right: .3rem;
      z-index: 99;
      .item{
        display: block;
        width: .42rem;
        // height: .24rem;
        margin: 0 .06rem;
      }
    }
  }
}

